import React from "react";
import { Link } from "react-router-dom";

import "./WelcomeScreen.css";

const WelcomeScreen = () => {
  const [roomName, setRoomName] = React.useState("");
  const [userType, setUserType] = React.useState("Admin");

  const handleRoomNameChange = (event) => {
    setRoomName(event.target.value);
  };

  const handleSelect = (event) => {
    setUserType(event.target.value);
  };

  return (
    <div className='home-container'>
      <img
        src='https://imero.de/assets/img/imero_logo.png'
        margin='auto'
        width='600'
        height='200'
        style={{ alignSelf: "center", opacity: "0.75" }}
        alt='new'
      />
      <h1 style={{ textAlign: "center", color: "black", opacity: "0.6" }}>
        Welcome to the Customer Support chat
      </h1>
      <input
        type='text'
        placeholder='Please enter your Manufacturer Id'
        value={roomName}
        onChange={handleRoomNameChange}
        className='text-input-field'
      />
      <select
        style={{ width: "200px", height: "50px", alignSelf: "center" }}
        onChange={handleSelect}
      >
        <option value='admin'>Admin</option>
        <option value='customer'>Customer</option>
      </select>
      <Link
        to={`/${roomName}/${userType}/${Math.random().toString().substr(2, 8)}`}
        className='enter-room-button'
      >
        Join chat
      </Link>
    </div>
  );
};

export default WelcomeScreen;
