import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./index.css";
import WelcomeScreen from "./WelcomeScreen/WelcomeScreen";
import Room from "./Room/Room";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path='/' component={WelcomeScreen} />
        <Route exact path='/:roomId/:userType/:sessionId' component={Room} />
      </Switch>
    </Router>
  );
}

export default App;
