import { useEffect, useRef, useState } from "react";
import socketIOClient from "socket.io-client";

//const SOCKET_SERVER_URL = "http://localhost:8000";
const SOCKET_SERVER_URL = "http://45.157.176.245:8000";
const NEW_MSG_EVENT = "newMessage";

const useChat = (roomId, userType, sesId) => {
  const [messages, setMessages] = useState([]);
  const [receiverID, setReceiverID] = useState("");
  const [currentSocketID, setCurrentSocketID] = useState("");
  const [existingUsers, setExistingUsers] = useState([]);
  const socketRef = useRef();
  var objArr = [];

  useEffect(() => {
    socketRef.current = socketIOClient(SOCKET_SERVER_URL, {
      query: { roomId, userType, sesId },
    });

    socketRef.current.on("users", (users) => {
      setCurrentSocketID(socketRef.current.id);
      setExistingUsers([...existingUsers, users]);
    });

    socketRef.current.on(NEW_MSG_EVENT, (message) => {
      const incomingMessage = {
        ...message,
        ownedByCurrentUser: message.senderId === socketRef.current.id,
      };
      setMessages((messages) => [...messages, incomingMessage]);
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [roomId]);

  useEffect(() => {
    if (userType === "customer" && currentSocketID != "") {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwcm9kdWN0SUQiOiI2MTA5MTU5MmEwMTU0NjAwMTU3ZDg2OGMiLCJ0YWdIYXNoIjoiMzBDQzhGMjA0QTc1QjNEMDQ4MDA3N0FBMTFGNjI0MTciLCJpYXQiOjE2MzE2OTk1NjMsImV4cCI6MTYzMTcwMDQ2M30.54GdO8nQB-ym3Pv6Ys0VUnsLBL9Fnj5dC4SdJHOKh9g",
          productID: roomId,
          companyID: roomId,
          serviceType: "chat",
          socketID: socketRef.current.id,
        }),
      };
      fetch(
        "https://facade.imero.io/facade/imeros/external/createSession",
        requestOptions
      ).then((response) => console.log(response.json()));
    }
  }, [currentSocketID]);

  useEffect(() => {
    socketRef.current.on("user connected", (user) => {
      setExistingUsers([...existingUsers, user]);
    });

    existingUsers.map(function (x) {
      x.map(function (y) {
        return objArr.push(y);
      });
    });

    const admin = objArr.find((obj) => obj.userType === "Admin");
    if (admin != null) {
      setReceiverID(admin.userID);
    }
  });

  const sendMessage = (messageBody, receiver) => {
    socketRef.current.emit(NEW_MSG_EVENT, {
      body: messageBody,
      senderId: socketRef.current.id,
      userType: userType,
      receiverId: receiver != "" ? receiver : receiverID,
    });
  };

  return { messages, sendMessage, currentSocketID };
};

export default useChat;
