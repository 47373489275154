import React from "react";

import "./Room.css";
import useChat from "../useChat";
import Image from "../Image";
import { useState, useEffect } from "react";
import { ListGroup, ListGroupItem, Form, Badge } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const Room = (props) => {
  const { roomId, userType, sesID } = props.match.params;
  const { messages, sendMessage, currentSocketID } = useChat(
    roomId,
    userType,
    sesID
  );
  const [newMessage, setNewMessage] = React.useState("");
  const [file, setFile] = React.useState();
  const [sessions, setSessions] = useState([]);
  const [customer, setCustomer] = React.useState("");
  const [customerID, setCustomerID] = useState("");

  const handleNewMessageChange = (e) => {
    setNewMessage(e.target.value);
  };

  useEffect(() => {
    fetch(
      `https://facade.imero.io/facade/imeros/external/getSessions?companyID=${roomId}&serviceType=chat`,
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        const sessions = [];
        response.sort((a, b) => b.created - a.created);
        response.forEach((session) => {
          if (new Date(session.created).getDate() === new Date().getDate()) {
            sessions.push(session);
          }
        });
        setSessions(sessions);
      })
      .catch((error) => console.log(error));
  }, [currentSocketID]);

  const handleSendMessage = (e) => {
    if (file) {
      const fileObj = {
        type: "file",
        body: file,
        mimeType: file.type,
        fileName: file.name,
      };

      setFile();
      sendMessage(fileObj, customer);
      setNewMessage("");
    } else if (newMessage.trim().length > 0) {
      sendMessage(newMessage, customer);
      setNewMessage("");
    }
  };

  const selectFile = (e) => {
    setNewMessage(e.target.files[0].name);
    setFile(e.target.files[0]);
  };

  const renderMessages = (message, i) => {
    if (message.body.type === "file") {
      const blob = new Blob([message.body.body], {
        type: message.body.mimeType,
      });
      return (
        <li
          key={i}
          className={`file-item ${
            message.ownedByCurrentUser ? "my-file" : "received-file"
          }`}
        >
          <p style={{ color: "white" }}>
            {message.userType === "Admin"
              ? "Admin: "
              : "Customer#" + message.senderId.substring(0, 5) + ": "}
          </p>
          <Image fileName={message.body.fileName} blob={blob} />
        </li>
      );
    }
    return (
      <li
        key={i}
        className={`message-item ${
          message.ownedByCurrentUser ? "my-message" : "received-message"
        }`}
      >
        {message.userType === "Admin"
          ? "Admin: " + message.body
          : "Customer#" +
            message.senderId.substring(0, 5) +
            ": " +
            message.body}
      </li>
    );
  };

  const alertClicked = (id, e) => {
    if (customerID !== id) {
      setCustomerID(id);
    }
    setCustomer(e.target.getAttribute("value"));
  };

  return (
    <div className='chat-room-container'>
      <h1 className='room-name'>
        <Badge pill='true' text='light' bg='info'>
          Manufacturer Id: {roomId}
        </Badge>
      </h1>
      {userType === "Admin" && (
        <h4
          style={{
            color: "white",
            opacity: "0.7",
          }}
        >
          <Badge bg='success'>Available User Sessions</Badge>
        </h4>
      )}
      {userType === "Admin" && (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "75px",
            flexDirection: "row",
            columnGap: "10px",
            overflow: "auto",
            border: "1px solid white",
            padding: "10px",
            marginBottom: "50px",
            alignItems: "center",
          }}
        >
          {userType === "Admin" &&
            sessions.map((session, i) => (
              <ListGroup>
                <ListGroup.Item
                  className={customerID === i ? "active" : null}
                  key={i}
                  value={session.socketID}
                  variant='dark'
                  onClick={alertClicked.bind(this, i)}
                >
                  user#{session.socketID.substring(0, 5)}
                </ListGroup.Item>
              </ListGroup>
            ))}
        </div>
      )}

      <div className='messages-container'>
        <ol className='messages-list'>{messages.map(renderMessages)}</ol>
      </div>
      <textarea
        value={newMessage}
        onChange={handleNewMessageChange}
        placeholder='Please enter a message'
        className='new-message-input-field'
      />
      <Form.Control onChange={selectFile} type='file' />
      <button onClick={handleSendMessage} className='send-message-button'>
        Send
      </button>
    </div>
  );
};

export default Room;
