import React, { useState, useEffect } from "react";
import pdficon from "../src/images/pdf_icon.png";

function Image(props) {
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    const reader = new FileReader();
    reader.readAsDataURL(props.blob);
    reader.onloadend = function () {
      setImageSrc(reader.result);
    };
  }, [props.blob]);

  return (
    <a href={imageSrc} download>
      <img
        style={
          props.blob.type === "application/pdf"
            ? { width: "150px", height: "auto" }
            : { marginLeft: "-1%", width: "200px", height: "auto" }
        }
        src={props.blob.type === "application/pdf" ? pdficon : imageSrc}
        alt={props.fileName}
      />
    </a>
  );
}

export default Image;
